import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import '../PlayGame.css'; 
import { gameData } from './allgame';
import Navbar from './Navbar';

function PlayGame() {
  const { id } = useParams();
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();

  const handleSearch = (e) => setSearchQuery(e.target.value);
  const toggleMenu = () => setMenuOpen(!menuOpen);
  
  const game = gameData.find((game) => String(game.id) === String(id));

  const otherGames = gameData
    .filter((game) => game.id !== id)
    .slice(0, 20); // Limit to 20 games

  useEffect(() => {
    // Scroll to top when the location (URL) changes
    window.scrollTo(0, 0);
  }, [location]);
 
  if (!game) {
    return <div className="error-message">Game not found</div>;
  }

  return (
    <div>
      {/* Add the Navbar at the top */}
      <Navbar />
  
      {/* Existing Play Game Page Code */}
      <div className="playgame-container">
        <div className="game-display">
          <h1 className="game-title">{game.title}</h1>
          <iframe
            src={game.url || 'about:blank'}
            title={game.title}
            className="game-iframe"
            loading="lazy"
          />
          {/* Share Section */}
          <div className="share-icons-container">
            <p className="share-text">Share this game:</p>
            <div className="share-icons">
              {/* Email */}
              <a
                href={`mailto:?subject=Check out this game!&body=Play it here: ${game.url}`}
                target="_blank"
                rel="noopener noreferrer"
                className="share-icon"
              >
                <i className="fas fa-envelope"></i>
              </a>
              {/* WhatsApp */}
              <a
                href={`https://wa.me/?text=Check out this game! Play it here: ${game.url}`}
                target="_blank"
                rel="noopener noreferrer"
                className="share-icon"
              >
                <i className="fab fa-whatsapp"></i>
              </a>
              {/* Instagram */}
              <a
                href="https://www.instagram.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="share-icon"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>
  
        {otherGames.length > 0 && (
          <div className="suggested-games">
            <h2 className="section-title">Other Games You May Like</h2>
            <div className="suggested-games-list">
              {otherGames.map((suggestedGame) => (
                <Link
                  key={suggestedGame.id}
                  to={`/play-game/${suggestedGame.id}`}
                  className="suggested-game-card"
                >
                  <img
                    src={suggestedGame.thumb}
                    alt={suggestedGame.title}
                    className="game-thumbnail"
                  />
                  <h3 className="game-card-title">{suggestedGame.title}</h3>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PlayGame;
