import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../Navbar.css';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="navbar">
      <div className="brand">
        <Link to="/">
          <img src="/image/frame.png" alt="GamePortal" style={{ height: '40px', width: 'auto' }} />
        </Link>
      </div>
      <div className="navbar-content">
        <input type="text" placeholder="Search" />
        <div className="hamburger" onClick={toggleMenu}>
          &#9776;
        </div>
        {/* The navigation links */}
        <nav className={menuOpen ? 'open' : ''}>
          <Link to="/">Home</Link>
          <Link to="/TrendingGames">Trending Games</Link>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
