import React from 'react';
import { Outlet, Link } from 'react-router-dom'; // If using React Router
import '../bottom.css'; // Import the footer styles

const Layout = () => {
  return (
    <div>
      {/* Main content goes here */}
      <div>
        <Outlet /> {/* Renders the current page content */}
      </div>

      {/* Bottom Bar */}
      <div className="bottom-bar">
        <div className="bottom-bar-content">
          {/* Home Icon Link with Custom Image */}
          <Link to="/" className="home-icon">
            {/* <img 
              src="/image/home.png" 
              alt="Home" 
              className="home-icon-image" 
            /> */}
          </Link>
          <Link to="/">Home</Link>
          <Link to="/AboutUs">About Us</Link>
          <Link to="/PrivacyPolicy">Privacy Policy</Link>
          <Link to="/Terms&Condition">Terms of Service</Link>
          <Link to="/Contact">Contact</Link>
        </div>
      </div>
    </div>
  );
};

export default Layout;
